import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import NavbarSection from "./elements/Navbar";
import { useSelector } from "react-redux";
import Footer from "./elements/Footer";
import { useTranslation } from "react-i18next";
import emailjs from "emailjs-com"; // Import EmailJS
import Api from "../../api/Api";

export default function Employment() {
  // const sendEmail = (e) => {
  //   e.preventDefault(); // Prevent default form submission

  //   emailjs
  //     .sendForm(
  //       "service_nuv4agc",
  //       "template_0tummte",
  //       form.current,
  //       "wZpCflMwKmeH1qFxF"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         alert("Message sent successfully!");
  //       },
  //       (error) => {
  //         console.log(error.text);
  //         alert("An error occurred, please try again.");
  //       }
  //     );
  // };

  const data = useSelector((state) => state.auth.public);
  const { t } = useTranslation();
  const form = useRef();
  const [message, setMessage] = useState(""); // State to hold the response message
  const [messageType, setMessageType] = useState(""); // State to hold message type ('success' or 'error')

  const sendEmail = async (e) => {
    e.preventDefault(); // Prevent default form submission

    const formData = new FormData(form.current); // Collect form data

    try {
      const response = await Api.post(
        "/send-employment", // Replace with your Laravel API endpoint
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        setMessageType("success");
        setMessage(t("Message sent successfully!"));
        form.current.reset(); // Clear the form after successful submission
      } else {
        setMessageType("error");
        setMessage(t("An error occurred, please try again."));
      }
    } catch (error) {
      console.error(error);
      setMessageType("error");
      setMessage(t("An error occurred, please try again."));
    }
  };

  return (
    <>
      <NavbarSection data={data} />
      <motion.div
        className="pagebanner"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="overlay"></div>
        <h1>{t("Contact us")}</h1>
      </motion.div>

      <motion.div
        id="menu"
        className="menu-products py-5"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="bg2"></div>
        <div className="container py-5">
          <div className="row contact-white">
            <motion.div
              className="col-md-5"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div className="contact-card">
                <h1 className="contact-title">{t("Recruitment Form")}</h1>
                <p className="contact-subtitle">
                  {t(
                    "Make sure to fill in the information correctly and accurately."
                  )}
                </p>
              </div>
            </motion.div>

            <motion.div
              className="col-md-7"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <form className="p-3" ref={form} onSubmit={sendEmail}>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <label className="form-label">{t("Job Post")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="job_post"
                      placeholder={t("Enter Job Post")}
                      required
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-6">
                    <label className="form-label">{t("First Name")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="first_name"
                      placeholder={t("First Name")}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">{t("Last Name")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="last_name"
                      placeholder={t("Last Name")}
                      required
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-6">
                    <label className="form-label">{t("Email")}</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder={t("Email")}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">{t("Phone Number")}</label>
                    <input
                      type="tel"
                      className="form-control"
                      name="phone"
                      placeholder={t("Phone Number")}
                      required
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-6">
                    <label className="form-label">{t("Birth Date")}</label>
                    <input
                      type="date"
                      className="form-control"
                      name="birth_date"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">{t("Place of Birth")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="place_of_birth"
                      placeholder={t("Place of Birth")}
                      required
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-6">
                    <label className="form-label">{t("Gender")}</label>
                    <select name="gender" className="form-control" required>
                      <option value="">{t("Select Gender")}</option>
                      <option value="male">{t("Male")}</option>
                      <option value="female">{t("Female")}</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">{t("Nationality")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="nationality"
                      placeholder={t("Nationality")}
                      required
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-6">
                    <label className="form-label">{t("Marital Status")}</label>
                    <select
                      name="marital_status"
                      className="form-control"
                      required
                    >
                      <option value="">{t("Select Marital Status")}</option>
                      <option value="single">{t("Single")}</option>
                      <option value="married">{t("Married")}</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">
                      {t("Military Service")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="military_service"
                      placeholder={t("Military Service")}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-6">
                    <label className="form-label">{t("Upload Full CV")}</label>
                    <input
                      type="file"
                      name="cv"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">
                      {t("Enter a verification code")}
                    </label>
                    <input
                      type="text"
                      name="verification_code"
                      className="form-control"
                      placeholder={t("Enter code")}
                      required
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <label className="form-label">{t("Note")}</label>
                  <textarea
                    name="note"
                    className="form-control"
                    rows="3"
                    placeholder={t("Additional Notes")}
                  ></textarea>
                </div>

                <div className="text-end">
                  <button type="submit" className="btn btn-send">
                    {t("Send")}
                  </button>
                </div>
              </form>
              {message && (
                <div
                  className={`mt-3 alert ${
                    messageType === "success" ? "alert-success" : "alert-danger"
                  }`}
                >
                  {message}
                </div>
              )}
            </motion.div>
          </div>
        </div>
      </motion.div>

      <motion.div
        className="container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="row">
          <div className="col-md-6">
            {data?.data.addressiframe ? (
              <iframe
                src={data?.data.addressiframe}
                width="100%"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            ) : null}
          </div>
          <div className="col-md-6">
            {data?.data.addressiframe2 ? (
              <iframe
                src={data?.data.addressiframe2}
                width="100%"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            ) : null}
          </div>
        </div>

        <div
          className="footer-line"
          style={{
            backgroundImage:
              "url(https://api.baderaldeensweets.com/public/storage/footer.png)",
          }}
        ></div>
      </motion.div>

      <Footer />
    </>
  );
}
