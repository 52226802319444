import { ActionTypes } from "../action-creator/types";

const reducer = (
  state = { certificates: [], loading: false, errors: false },
  action
) => {
  switch (action.type) {
    case ActionTypes.LOAD_CERTIFICATES:
      return {
        ...state,
        certificates: action.payload.certificates,
      };

    case ActionTypes.ADD_CERTIFICATE:
      return {
        ...state,
        certificates: [...state.certificates, action.payload],
      };

    case ActionTypes.UPDATE_CERTIFICATE:
      return {
        ...state,
        certificates: state.certificates.map((client) =>
          client.id === action.payload.id ? action.payload : client
        ),
      };

    case ActionTypes.DELETE_CERTIFICATE:
      return {
        ...state,
        certificates: state.certificates.filter(
          (client) => client.id !== action.payload
        ),
      };

    default:
      return state;
  }
};

export default reducer;
