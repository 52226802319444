import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import NavbarSection from "./elements/Navbar";
import Footer from "./elements/Footer";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Api from "../../api/Api";

export default function QrMenu() {
  const data = useSelector((state) => state.auth.public);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [arabicPdf, setArabicPdf] = useState(null);
  const [englishPdf, setEnglishPdf] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch PDF links from the API
    Api.get("/get-pdfs") // Change to your actual API route
      .then((response) => {
        setArabicPdf(response.data.arabic_pdf);
        setEnglishPdf(response.data.english_pdf);
      })
      .catch((error) => console.error("Error fetching PDFs:", error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <NavbarSection data={data} />

      <motion.div
        className="pagebanner"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="overlay"></div>
        <h1>{t("Our Menu")}</h1>
      </motion.div>

      <motion.section
        id="menu"
        className="menu-products py-5"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="bg2"></div>
        <div className="container text-center">
          {loading ? (
            <p>{t("Loading menu...")}</p>
          ) : (
            <>
              {i18n.language === "ar" && arabicPdf ? (
                <iframe
                  src={`${process.env.REACT_APP_SERVER_URL}${arabicPdf}`} // Adjust path if needed
                  width="100%"
                  height="600px"
                ></iframe>
              ) : i18n.language === "en" && englishPdf ? (
                <iframe
                  src={`${process.env.REACT_APP_SERVER_URL}${englishPdf}`}
                  width="100%"
                  height="600px"
                ></iframe>
              ) : (
                <p>{t("Menu not available.")}</p>
              )}
            </>
          )}
        </div>
      </motion.section>

      <Footer />
    </>
  );
}
