import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Icon from "@mdi/react";
import { mdiCartOutline } from "@mdi/js";
import CartModel from "./CartModel";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Footer() {
  const data = useSelector((state) => state.auth.public);
  const state = useSelector((state) => state);
  const [showCart, setShowCart] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);

  // Show or hide the button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };

  const country = useSelector((state) => state.auth.country);
  const { t, i18n } = useTranslation();

  const handleShowCart = () => {
    setShowCart(!showCart);
  };

  function getFirst20Words(paragraph) {
    // Split the paragraph into words using spaces as the delimiter
    const words = paragraph.split(/\s+/);

    // Slice the first 20 words and join them back into a string

    return words.slice(0, 20).join(" ");
  }

  return (
    <div>
      {country !== null && country !== "SY" ? (
        <div className="cart-button" onClick={handleShowCart}>
          <Icon path={mdiCartOutline} size={"30px"} color="#fff" />
          <span className="cart-number-item">{state.cart.cart.length}</span>
        </div>
      ) : null}

      {showCart ? (
        <CartModel show={showCart} handleClose={setShowCart} data={data} />
      ) : null}

      {/* <div className="footer">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-2 align-content-center">
                <i className="mdi mdi-phone"></i>
              </div>
              <div className="col-10">
                <div className="phones">
                  <a href="">
                    {i18n.language === "en"
                      ? data?.data.phone
                      : data?.data.phone_ar}
                  </a>
                  <a href="">
                    {i18n.language === "en"
                      ? data?.data.phone2
                      : data?.data.phone2_ar}
                  </a>
                  <a href="">
                    {i18n.language === "en"
                      ? data?.data.phone3
                      : data?.data.phone3_ar}
                  </a>
                  <a href="">
                    {i18n.language === "en"
                      ? data?.data.phone4
                      : data?.data.phone4_ar}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-2 align-content-center">
                <i className="mdi mdi-map"></i>
              </div>
              <div className="col-10">
                <p>
                  <a href="">{data?.data.address_ar}</a>
                </p>
                <p>
                  <a href="">{data?.data.address}</a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <p className="copyright">{t("Copyright © 2024 - Bader Al-Dein")} </p>
      </div> */}

      <footer
        className="bg-dark text-white "
        style={{
          backgroundImage:
            "url(https://api.baderaldeensweets.com/public/storage/headerbg.jpeg)",
        }}
      >
        <div className="overlay py-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 text-start">
                <h5>{t("Bader Al Deen")}</h5>

                {data?.data ? (
                  <p>
                    {i18n.language === "en"
                      ? getFirst20Words(data?.data.aboutusdesc)
                      : getFirst20Words(data?.data.aboutusdesc_ar)}
                  </p>
                ) : null}
              </div>

              {/* Middle Section */}
              <div className="col-lg-3 col-md-6">
                <h5>{t("Website Links")} </h5>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li>
                    <Link to="/syria">{t("Syria menu")}</Link>
                  </li>
                  {state.auth.country !== null && state.auth.country !== "SY" && (
                    <li>
                      <Link to="/amman">{t("Amman menu")}</Link>
                    </li>
                  )}
                  <li>
                    <Link to="/news">{t("News & Events")}</Link>
                  </li>
                  {data?.pages
                    ?.filter((page) => page.language === i18n.language)
                    .map((page) => (
                      <li key={page.id}>
                        <Link to={`/page/${page.slug}`}>{page.title}</Link>
                      </li>
                    ))}
                  <li>
                    <Link to="/contact">{t("Contact us")}</Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 ">
                <h5>{t("Contact Info")}</h5>
                <ul className="list-unstyled contact-list">
                  {i18n.language === "en" ? (
                    <>
                      {data?.data.address && (
                        <li>
                          <a
                            href={data?.data.addresslink}
                            className="text-white mx-2"
                          >
                            <span className="icon">
                              <i className="mdi mdi-map"></i>
                            </span>{" "}
                            {data?.data.address}
                          </a>
                        </li>
                      )}
                      {data?.data.address2 && (
                        <li>
                          <a
                            href={data?.data.addresslink2}
                            className="text-white mx-2"
                          >
                            <span className="icon">
                              <i className="mdi mdi-map"></i>
                            </span>{" "}
                            {data?.data.address2}
                          </a>
                        </li>
                      )}
                    </>
                  ) : (
                    <>
                      {data?.data.address_ar && (
                        <li>
                          <a
                            href={data?.data.addresslink_ar}
                            className="text-white mx-2"
                          >
                            <span className="icon">
                              <i className="mdi mdi-map"></i>
                            </span>{" "}
                            {data?.data.address_ar}
                          </a>
                        </li>
                      )}
                      {data?.data.address2_ar && (
                        <li>
                          <a
                            href={data?.data.addresslink2_ar}
                            className="text-white mx-2"
                          >
                            <span className="icon">
                              <i className="mdi mdi-map"></i>
                            </span>{" "}
                            {data?.data.address2_ar}
                          </a>
                        </li>
                      )}
                    </>
                  )}

                  {data?.data.publicemail ? (
                    <li>
                      <a
                        href={`tel:${data?.data.publicemail}`}
                        className="text-white mx-2"
                      >
                        <span className="icon">
                          <i className="mdi mdi-email"></i>
                        </span>
                        <span
                          style={{ direction: "ltr", display: "inline-block" }}
                        >
                          {data?.data.publicemail}
                        </span>
                      </a>
                    </li>
                  ) : null}

                  {data?.data.phone ? (
                    <li>
                      <a
                        href={`tel:${data?.data.phone}`}
                        className="text-white mx-2"
                      >
                        <span className="icon">
                          <i className="mdi mdi-phone"></i>
                        </span>
                        <span
                          style={{ direction: "ltr", display: "inline-block" }}
                        >
                          {data?.data.phone}
                        </span>
                      </a>
                    </li>
                  ) : null}
                  {data?.data.phone2 ? (
                    <li>
                      <a
                        href={`tel:${data?.data.phone2}`}
                        className="text-white mx-2"
                      >
                        <span className="icon">
                          <i className="mdi mdi-phone"></i>
                        </span>
                        <span
                          style={{ direction: "ltr", display: "inline-block" }}
                        >
                          {data?.data.phone2}
                        </span>
                      </a>
                    </li>
                  ) : null}
                  {data?.data.phone3 ? (
                    <li>
                      <a
                        href={`tel:${data?.data.phone3}`}
                        className="text-white mx-2"
                      >
                        <span className="icon">
                          <i className="mdi mdi-phone"></i>
                        </span>
                        <span
                          style={{ direction: "ltr", display: "inline-block" }}
                        >
                          {data?.data.phone3}
                        </span>
                      </a>
                    </li>
                  ) : null}
                  {data?.data.phone4 ? (
                    <li>
                      <a
                        href={`tel:${data?.data.phone4}`}
                        className="text-white mx-2"
                      >
                        <span className="icon">
                          <i className="mdi mdi-phone"></i>
                        </span>
                        <span
                          style={{ direction: "ltr", display: "inline-block" }}
                        >
                          {data?.data.phone4}
                        </span>
                      </a>
                    </li>
                  ) : null}
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 ">
                <h5>{t("Follow Us")}</h5>
                <div className="row mt-4 social-media-list">
                  <div className="col ">
                    {data?.data.facebook ? (
                      <a href={data?.data.facebook} className="text-white mx-2">
                        <i className="mdi mdi-facebook"></i>
                      </a>
                    ) : null}
                    {data?.data.instagram ? (
                      <a
                        href={data?.data.instagram}
                        className="text-white mx-2"
                      >
                        <i className="mdi mdi-instagram"></i>
                      </a>
                    ) : null}
                    {data?.data.linkedin ? (
                      <a href={data?.data.linkedin} className="text-white mx-2">
                        <i className="mdi mdi-linkedin"></i>
                      </a>
                    ) : null}
                    {data?.data.tiktok ? (
                      <a href={data?.data.tiktok} className="text-white mx-2">
                        <svg
                          viewBox="0 0 448 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill="#fff"
                            d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
                          />
                        </svg>
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4 copyright">
              <div className="col text-center">
                <p className=" m-0">{t("Copyright © 2024 - Bader Al-Dein")} </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {isVisible && (
        <button
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "20px",
            left: "20px",
            zIndex: 1000,
            background: "#7c6139",
            color: "#fff",
            border: "none",
            borderRadius: "50%",
            width: "50px",
            height: "50px",
            cursor: "pointer",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "24px",
            transition: ".3s",
          }}
        >
          <i className="mdi mdi-arrow-up"></i>
        </button>
      )}
    </div>
  );
}
