import { ActionTypes } from "../action-creator/types";

const reducer = (
  state = {
    testimonials: [],

    images: [],
    loading: false,
    errors: false,
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.LOADING_TESTIMONIALS:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.LOADING_TESTIMONIALS_FALSE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.ERRORS_TESTIMONIALS:
      return {
        ...state,
        errors: true,
      };
    case ActionTypes.ERRORS_TESTIMONIALS_FALSE:
      return {
        ...state,
        errors: false,
      };
    case ActionTypes.LOAD_TESTIMONIALS:
      return {
        ...state,
        // testimonials: action.payload.testimonials.map((item) => ({ ...item, image: action.payload.images.find((x) => x.id == item.image)?.image })),
        testimonials: action.payload.testimonials,

        images: action.payload.images,
      };
    case ActionTypes.ADD_TESTIMONIAL:
      return {
        ...state,
        testimonials: [...state.testimonials, action.payload],
      };
    case ActionTypes.UPDATE_TESTIMONIAL:
      return {
        ...state,
        testimonials: state.testimonials.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case ActionTypes.DELETE_TESTIMONIAL:
      return {
        ...state,
        testimonials: state.testimonials.filter(
          (item) => item.id === action.payload
        ),
      };
    case ActionTypes.UPDATE_TESTIMONIALS:
      return {
        ...state,
        testimonials: state.testimonials.map((item) =>
          action.payload.ids.includes(item.item_id)
            ? { ...item, stock_state: action.payload.stock_state }
            : item
        ),
      };

    default:
      return state;
  }
};

export default reducer;
