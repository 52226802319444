import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NavbarSection from "./elements/Navbar";
import Footer from "./elements/Footer";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper components
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import Api from "../../api/Api";
import { toast } from "react-toastify";
import Testimonials from "../dashboard/pages/Testimonials";

export default function Clients() {
  const [clients, setClients] = useState(null);
  const [testimonials, setTestimonials] = useState(null);
  const data = useSelector((state) => state.auth.public);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    Api.get("/clients/all")
      .then((response) => {
        setClients(response.data.clients);
      })
      .catch((err) => {
        toast.error(err.response?.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }, []);

  useEffect(() => {
    Api.get("/testimonials/all")
      .then((response) => {
        console.log(response.data);

        setTestimonials(response.data.testimonials);
      })
      .catch((err) => {
        toast.error(err.response?.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }, []);

  return (
    <>
      <NavbarSection data={data} />

      <div className="pagebanner">
        <div className="overlay"></div>
        <h1>{t("Our clients")}</h1>
      </div>

      <div className="container py-5">
        <h1 className="h2 mb-5 text-primary text-center">
          {t("Our Best Client")}
        </h1>

        <div className="row">
          {clients !== null ? (
            clients.map((client, index) => (
              <div className=" col-6 col-md-3">
                <div className="client-logo " key={index}>
                  <img
                    src={`https://api.baderaldeensweets.com/public/storage/${client.image}`}
                    alt={`Client Logo ${index + 1}`}
                  />
                </div>
              </div>
            ))
          ) : (
            <div className="text-center py-5">
              <p>Loading clients...</p>
            </div>
          )}
        </div>

        <h2 className="h2 mt-5 text-primary text-center">
          {t("What Our Clients Say About Us")}
        </h2>

        {/* Swiper Slider for Testimonials */}
        <Swiper
          spaceBetween={20}
          breakpoints={{
            0: {
              slidesPerView: 1, // Show 1 slide per view on mobile (0px and up)
            },
            768: {
              slidesPerView: 2, // Show 2 slides per view on tablets and desktop (768px and up)
            },
          }}
          loop={true}
          navigation
          pagination={{ clickable: true }}
          modules={[Pagination]}
          dir="rtl"
        >
          {testimonials !== null ? (
            testimonials
              .filter((testimonial) => testimonial.lang === i18n.language)
              .map((testimonial, index) => (
                <SwiperSlide key={index}>
                  <div className="testimonial-card text-center p-4">
                    <div className="testimonial-avatar mb-3">
                      <img
                        src={`https://api.baderaldeensweets.com/public/storage/${testimonial.image}`}
                        alt="Client Avatar"
                        className="rounded-circle"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                    <span className="quote-mark left">
                      <svg
                        width="65"
                        height="47"
                        viewBox="0 0 65 47"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M50.8439 29.0007C59.2078 28.8401 65 22.9436 65 14.5004C65 6.05712 59.0456 0 50.3573 0C41.669 0 35.7147 5.89651 35.7147 14.5004C35.7147 27.4061 45.3645 41.7459 49.7086 47H58.5591C55.8252 41.7459 52.6047 34.7366 50.8323 29.0007H50.8439Z"
                          fill="#417E71"
                        />
                        <path
                          d="M15.129 29.0007C23.493 28.8401 29.2852 22.9436 29.2852 14.5004C29.2852 6.05712 23.3308 0 14.6425 0C5.9542 0 -0.000177383 5.89651 -0.000177383 14.5004C-0.000177383 27.4061 9.64962 41.7459 13.9938 47H22.8442C20.1103 41.7459 16.8899 34.7366 15.1174 29.0007H15.129Z"
                          fill="#417E71"
                        />
                      </svg>
                    </span>
                    <p className="testimonial-text">
                      {testimonial.description}
                    </p>
                    <span className="quote-mark right">
                      <svg
                        width="65"
                        height="47"
                        viewBox="0 0 65 47"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.1561 17.9993C5.79219 18.1599 0 24.0564 0 32.4996C0 40.9429 5.95438 47 14.6427 47C23.331 47 29.2853 41.1035 29.2853 32.4996C29.2853 19.5939 19.6355 5.25409 15.2914 0H6.44092C9.17484 5.25409 12.3953 12.2634 14.1677 17.9993H14.1561Z"
                          fill="#417E71"
                        />
                        <path
                          d="M49.871 17.9993C41.507 18.1599 35.7148 24.0564 35.7148 32.4996C35.7148 40.9429 41.6692 47 50.3575 47C59.0458 47 65.0002 41.1035 65.0002 32.4996C65.0002 19.5939 55.3504 5.25409 51.0062 0H42.1558C44.8897 5.25409 48.1101 12.2634 49.8826 17.9993H49.871Z"
                          fill="#417E71"
                        />
                      </svg>
                    </span>
                    <div className="testimonial-signature mt-3">
                      {testimonial.name}
                    </div>
                  </div>
                </SwiperSlide>
              ))
          ) : (
            <div className="text-center py-5">
              <p>Loading Testimonials...</p>
            </div>
          )}
        </Swiper>
      </div>

      <Footer />
    </>
  );
}
