import React, { useEffect } from "react";
import { mdiDelete, mdiDotsVertical, mdiPencil, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../shared/Spinner";
import { deleteCategory } from "../../../state/action-creator/items-actions";
import CategoryAdd from "./General/CategoryAdd";
import { Link } from "react-router-dom";
import { timeSince } from "./General/timeSince";
import { useHistory } from "react-router-dom";
import {
  changePageState,
  deletePage,
} from "../../../state/action-creator/pages-actions";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { useTranslation } from "react-i18next";
import AddTestimonial from "./General/AddTestimonial";
import {
  deleteTestimonial,
  loadTestimonials,
} from "../../../state/action-creator/testimonials-actions";

export default function Testimonials() {
  const [showAdd, setShowAdd] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [editCategory, setEditCategory] = React.useState([]);
  const [editItem, setEditItem] = React.useState({});
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(loadTestimonials());
  }, []);

  const handleAdd = () => {
    setEditItem({});
    setShowAdd(!showAdd);
  };

  const handleEdit = (item) => {
    setEditItem(item);
    setShowAdd(!showAdd);
  };

  const handleDelete = (id) => {
    dispatch(deleteTestimonial({ id: id }));
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(categories);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setCategories(tempData);
  };

  const handleState = (data) => {
    dispatch(changePageState(data));
  };

  return (
    <div className="main-panel">
      {state.auth.isLoading ? <Spinner /> : null}
      <div className="page-header">
        <h3 className="page-title d-flex align-items-center">
          <span className="page-title-icon bg-gradient-dark text-white mr-2">
            <i className="mdi mdi-account"></i>
          </span>
          {t("Testimonials")}
        </h3>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="custom-card-header mb-5">
                <h4 className="card-title ">{t("All Testimonials")}</h4>

                <button
                  type="button"
                  className="btn btn-gradient-dark btn-sm btn-icon-text menu-add-button"
                  onClick={handleAdd}
                >
                  {t("Add Testimonial")}
                  <Icon path={mdiPlus} size={1} />
                </button>
              </div>

              {!isLoading ? (
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>{t("ID")}</th>
                        <th>{t("Title")}</th>

                        <th>{t("Action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.testimonials.testimonials?.map((testimonial) => (
                        <tr key={testimonial.id}>
                          <td>{testimonial.id}</td>
                          <td>{testimonial.name}</td>

                          <td>
                            <div className="menu-edit-button">
                              <span onClick={() => handleEdit(testimonial)}>
                                <Icon path={mdiPencil} size={1} />
                              </span>
                              <span
                                onClick={() => handleDelete(testimonial.id)}
                              >
                                <Icon path={mdiDelete} size={1} />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div
                  className="spinner-border"
                  role="status"
                  style={{ margin: "100px auto", display: "block" }}
                >
                  <span className="sr-only">{t("Loading...")}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showAdd ? (
        <AddTestimonial
          show={showAdd}
          handleClose={setShowAdd}
          data={editItem}
        />
      ) : null}
    </div>
  );
}
