import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useEffect } from "react";
import { Alert, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { ActionTypes } from "../../../state/action-creator/types";

import dollarsToCents from "dollars-to-cents";

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { mdiAlertCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import TimePicker from "react-bootstrap-time-picker";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  firstname: yup.string().max(50).required("First name is a required field"),
  lastname: yup.string().max(50).required("Last name is a required field"),
  email: yup.string().max(255).email().required("Email is a required field"),
  phone: yup.string().required("Phone is a required field"),

  addressline1: yup.string().required("Address line 1 is a required field"),

  addressline2: yup.string(),
  city: yup.string().required("City is a required field"),
  province: yup.string().required("Province is a required field"),

  postalcode: yup.string().required("Postal code is a required field"),
});

export default function Checkout({ show, handleClose }) {
  const [isLoading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [checkoutErrors, setCheckoutErrors] = React.useState("");
  const [deliveryFees, setDeliveryFees] = React.useState(0);
  const [deliveryTips, setDeliveryTips] = React.useState(0);
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [pickupTime, setPickupTime] = React.useState(0);
  const [outOfStock, setOutOfStock] = React.useState(false);
  const [taxes, setTaxes] = React.useState(0);

  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const data = useSelector((state) => state.auth.public);

  useEffect(() => {
    const ids = state.cart.cart.map((order) => order.item.item_id);

    let items = data.items.filter((item) => ids.includes(item.item_id));

    items.map((item) => {
      if (!item.stock_state) {
        setOutOfStock(() => true);
      }
    });
  }, []);

  useEffect(() => {
    if (data.tax.length != 0) {
      let taxesRate = 0;

      data.tax.forEach((tax) => {
        taxesRate = taxesRate + Number(tax.rate);
      });

      setTaxes(taxesRate);
    }
  }, []);

  const today = new Date();
  const thisTime = today.getHours() + ":" + 30;

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  console.log(state.cart.cart);

  const subTotal = state.cart.cart.reduce((prev, item) => {
    return prev + Number(item.finalPrice);
  }, 0);
  const tax = (subTotal / 100) * taxes;

  useEffect(() => {
    setTotalPrice(subTotal + tax);
  }, [subTotal, taxes]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  console.log(errors);

  useEffect(() => {
    setTotalPrice(() => subTotal + tax);
    setDeliveryFees(() => 0);
  }, []);

  const handlePaymaet = async (formData) => {
    setCheckoutErrors(() => "");
    setLoading(() => true);

    axios
      .post(process.env.REACT_APP_SERVER_URL + "/api/order/payment", {
        amount: dollarsToCents(totalPrice),

        username: state.auth.user.username,
        pickuptime: pickupTime,
        order: JSON.stringify(state.cart.cart),
        subTotal,
        tax,
        totalPrice: dollarsToCents(totalPrice),

        ...formData,
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(() => false);
          dispatch({
            type: ActionTypes.CHECKOUT_SUCCEEDED,
            payload: response.data.orderData,
          });
          setShowModal(false);
        }
      })
      .catch((error) => {
        setLoading(() => false);
        setCheckoutErrors(() => error.response?.data.message);
      });
  };

  const onSubmit = async (formData) => {
    handlePaymaet(formData);
  };

  return (
    <Modal
      className="checkout"
      show={showModal}
      size="xl"
      fullscreen="md-down"
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton className="checkout-title">
        <h3>{t("Checkout Details")}</h3>
      </Modal.Header>
      <Modal.Body className="p-5">
        <Row>
          <Col
            xs={12}
            md={8}
            xl={{ order: 1 }}
            lg={{ order: 1 }}
            md={{ order: 2 }}
            sm={{ order: 2 }}
            xs={{ order: 2 }}
          >
            {checkoutErrors != "" ? (
              <Alert variant="danger">{checkoutErrors}</Alert>
            ) : (
              ""
            )}
            {outOfStock ? (
              <Alert variant="danger">
                {t("Some items in the cart are out of stock")}
              </Alert>
            ) : null}

            <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
              <section className="form-section">
                <h4>
                  <i className="mdi mdi-account-outline"></i> {t("Billing")}
                </h4>
                <div className="form-inline justify-content-between">
                  <Form.Group className="flex-fill mr-4 mb-4">
                    <label htmlFor="firstname" className="mb-2 checkout-label">
                      {t("First Name")}
                    </label>
                    <Form.Control
                      type="text"
                      id="firstname"
                      placeholder={t("First Name")}
                      className={`w-100 ${
                        errors.firstname ? "is-invalid" : null
                      }`}
                      {...register("firstname")}
                    />
                    <small className="invalid-feedback">
                      {errors.firstname?.message}
                    </small>
                  </Form.Group>
                  <Form.Group className="flex-fill mb-4">
                    <label htmlFor="lastname" className="mb-2  checkout-label">
                      {t("Last Name")}
                    </label>
                    <Form.Control
                      type="text"
                      id="lastname"
                      placeholder={t("Last Name")}
                      className={`w-100 ${
                        errors.lastname ? "is-invalid" : null
                      }`}
                      {...register("lastname")}
                    />
                    <small className="invalid-feedback">
                      {errors.lastname?.message}
                    </small>
                  </Form.Group>
                </div>
                <Form.Group className="flex-fill mb-4">
                  <label htmlFor="phone" className="mb-2  checkout-label">
                    {t("Phone")}
                  </label>
                  <Form.Control
                    type="text"
                    id="phone"
                    placeholder={t("Phone")}
                    className={`w-100 ${errors.phone ? "is-invalid" : null}`}
                    {...register("phone")}
                  />
                  <small className="invalid-feedback">
                    {errors.phone?.message}
                  </small>
                </Form.Group>
                <Form.Group className="flex-fill mb-4">
                  <label htmlFor="email" className="mb-2 checkout-label">
                    {t("Email")}
                  </label>
                  <Form.Control
                    type="text"
                    id="email"
                    placeholder={t("Email")}
                    className={`w-100 ${errors.email ? "is-invalid" : null}`}
                    {...register("email")}
                  />
                  <small className="invalid-feedback">
                    {errors.email?.message}
                  </small>
                </Form.Group>
              </section>

              <section className={`form-section`}>
                <h4>
                  <i className="mdi mdi-google-maps"></i> {t("Address")}
                </h4>

                <Form.Group className="flex-fill mr-4 mb-4">
                  <label htmlFor="addressline1" className="mb-2 checkout-label">
                    {t("Address Line 1")}
                  </label>
                  <Form.Control
                    type="text"
                    id="addressline1"
                    placeholder={t("Address Line 1")}
                    className={`w-100 ${
                      errors.addressline1 ? "is-invalid" : null
                    }`}
                    {...register("addressline1")}
                  />
                  <small className="invalid-feedback">
                    {errors.addressline1?.message}
                  </small>
                </Form.Group>
                <Form.Group className="flex-fill mb-4">
                  <label
                    htmlFor="addressline2"
                    className="mb-2  checkout-label"
                  >
                    {t("Address Line 2")}
                  </label>
                  <Form.Control
                    type="text"
                    id="addressline2"
                    placeholder={t("Address Line 2")}
                    className={`w-100 ${
                      errors.addressline2 ? "is-invalid" : null
                    }`}
                    {...register("addressline2")}
                  />
                  <small className="invalid-feedback">
                    {errors.addressline2?.message}
                  </small>
                </Form.Group>

                <Form.Group className="flex-fill mb-4">
                  <label htmlFor="city" className="mb-2  checkout-label">
                    {t("Town / City")}
                  </label>
                  <Form.Control
                    type="text"
                    id="city"
                    placeholder={t("Town / City")}
                    className={`w-100 ${errors.city ? "is-invalid" : null}`}
                    {...register("city")}
                  />
                  <small className="invalid-feedback">
                    {errors.city?.message}
                  </small>
                </Form.Group>

                <Form.Group className="flex-fill mb-4">
                  <label htmlFor="province" className="mb-2  checkout-label">
                    {t("Province")}
                  </label>
                  <Form.Control
                    type="text"
                    id="province"
                    placeholder={t("Province")}
                    className={`w-100 ${errors.province ? "is-invalid" : null}`}
                    {...register("province")}
                  />
                  <small className="invalid-feedback">
                    {errors.province?.message}
                  </small>
                </Form.Group>

                <Form.Group className="flex-fill mb-4">
                  <label htmlFor="postalcode" className="mb-2  checkout-label">
                    {t("Postal code")}
                  </label>
                  <Form.Control
                    type="text"
                    id="postalcode"
                    placeholder={t("Postal code")}
                    className={`w-100 ${
                      errors.postalcode ? "is-invalid" : null
                    }`}
                    {...register("postalcode")}
                  />
                  <small className="invalid-feedback">
                    {errors.postalcode?.message}
                  </small>
                </Form.Group>
              </section>

              <section className="form-section">
                <h4>
                  <i className="mdi mdi-account-outline"></i>{" "}
                  {t("Additional information")}
                </h4>
                <Form.Group className="flex-fill mb-4">
                  <label htmlFor="note" className="mb-2  checkout-label">
                    {t("Order notes (optional)")}
                  </label>
                  <textarea
                    id="note"
                    placeholder={t("Order notes (optional)")}
                    className={`form-control w-100 ${
                      errors.note ? "is-invalid" : null
                    }`}
                    {...register("note")}
                  ></textarea>
                  <small className="invalid-feedback">
                    {errors.note?.message}
                  </small>
                </Form.Group>
              </section>
              {/* <section className="form-section">
                <h4>
                  <i className="mdi mdi-wallet-outline"></i> Payment
                </h4> */}
              {/* <Form.Group className="flex-fill mb-4">
                  <label for="stripe-card-element">
                    Card Number <span className="required">*</span>
                  </label>
                  <fieldset className="FormGroup">
                    <div className="FormRow">
                      <CardNumberElement options={CARD_OPTIONS} />
                    </div>
                  </fieldset>
                </Form.Group> */}

              {/* <Form.Group className="d-flex mb-4">
                  <div className="col-md-6 pl-0">
                    <label for="stripe-card-element">
                      Card Expiry <span className="required">*</span>
                    </label>
                    <fieldset className="FormGroup">
                      <div className="FormRow">
                        <CardExpiryElement options={CARD_OPTIONS} />
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-md-6 pr-0">
                    <label for="stripe-card-element">
                      Card Cvc <span className="required">*</span>
                    </label>
                    <fieldset className="FormGroup">
                      <div className="FormRow">
                        <CardCvcElement options={CARD_OPTIONS} />
                      </div>
                    </fieldset>
                  </div>
                </Form.Group> */}
              {/* </section> */}

              {state.auth.isAuthenticated ? (
                <button
                  disabled={isLoading ? true : false}
                  className="btn btn-dark"
                >
                  {!isLoading ? (
                    `Pay ${totalPrice.toFixed(2)} OMR`
                  ) : (
                    <Spinner animation="border" size="sm" />
                  )}
                </button>
              ) : (
                <Link to="/login" className="btn btn-dark" type="button">
                  {t("Login")}
                </Link>
              )}
            </form>
          </Col>
          <Col
            xs={12}
            md={4}
            xl={{ order: 2 }}
            lg={{ order: 2 }}
            md={{ order: 1 }}
            sm={{ order: 1 }}
            xs={{ order: 1 }}
          >
            <section className="form-section summary-section">
              <h4>
                <i className="mdi mdi-clipboard-text-outline"></i>{" "}
                {t("Summary")}
              </h4>
              {/* <h4 className="checkout-subtitle p-4" style={{ background: "#282b2e", color: "#fff" }}>
              Summary
            </h4> */}
            </section>

            {state.cart.cart.length ? (
              state.cart.cart.map((item) => (
                <div
                  className="dropdown-item preview-item cart-box"
                  key={item.id}
                >
                  <div className="preview-item-content w-100 d-flex align-items-start flex-column justify-content-center">
                    <div className="media">
                      <div className="position-relative mr-3">
                        <div
                          className="cart-img"
                          style={{
                            backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/storage/${item.item.image})`,
                          }}
                        ></div>
                        <div className="count">{item.howMany}</div>
                      </div>
                      <div className="media-body">
                        <h5 className="font-weight-bold">{item.item.name}</h5>
                      </div>
                      <div className="price font-weight-bold mr-2">
                        {`${item.finalPrice}`} {t("OMR")}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>nothing</p>
            )}

            <div className="summary-price">
              <p>
                {t("Sub Total")}:{" "}
                <span>
                  {`${subTotal}`} {t("OMR")}
                </span>
              </p>

              <p>
                {t("Tax")}:{" "}
                <span>
                  {`${tax}`} {t("OMR")}
                </span>
              </p>

              <p>
                {t("Total")}:{" "}
                <span>
                  {`${totalPrice}`} {t("OMR")}
                </span>
              </p>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
