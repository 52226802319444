import { ActionTypes } from "./types";
import { toast } from "react-toastify";
import Api from "../../api/Api";

export const loadTestimonials = () => (dispatch, getState) => {
  Api.get("/testimonials/all")
    .then((response) => {
      dispatch({
        type: ActionTypes.LOAD_TESTIMONIALS,
        payload: response.data,
      });
    })
    .catch((err) => {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const addTestimonial = (data) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.LOADING_TESTIMONIALS });
  let req = Api.post("/testimonial/create", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({ type: ActionTypes.LOADING_TESTIMONIALS_FALSE });
        dispatch({
          type: ActionTypes.ADD_TESTIMONIAL,
          payload: data.data,
        });
        return "The Testimonial has been Added";
      },
    },
    error: {
      render({ data }) {
        dispatch({ type: ActionTypes.LOADING_TESTIMONIALS_FALSE });
        return data.response.data.message;
      },
    },
  });
};

export const updateTestimonial = (data) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.LOADING_TESTIMONIALS });

  let req = Api.post("/testimonial/update", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.UPDATE_TESTIMONIAL,
          payload: data.data,
        });
        dispatch({ type: ActionTypes.LOADING_TESTIMONIALS_FALSE });
        return "The Testimonial has been updated";
      },
    },
    error: {
      render({ data }) {
        dispatch({ type: ActionTypes.LOADING_TESTIMONIALS_FALSE });
        return data.response.data.message;
      },
    },
  });
};

export const deleteTestimonial = (data) => (dispatch, getState) => {
  let req = Api.post("/testimonial/delete", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render() {
        dispatch({
          type: ActionTypes.DELETE_TESTIMONIAL,
          payload: data,
        });
        return "The Testimonial has Been Deleted";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};
