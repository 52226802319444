import { ActionTypes } from "../action-creator/types";

const reducer = (
  state = { clients: [], loading: false, errors: false },
  action
) => {
  switch (action.type) {
    case ActionTypes.LOAD_CLIENTS:
      return {
        ...state,
        clients: action.payload.clients,
      };

    case ActionTypes.ADD_CLIENT:
      return {
        ...state,
        clients: [...state.clients, action.payload],
      };

    case ActionTypes.UPDATE_CLIENT:
      return {
        ...state,
        clients: state.clients.map((client) =>
          client.id === action.payload.id ? action.payload : client
        ),
      };

    case ActionTypes.DELETE_CLIENT:
      return {
        ...state,
        clients: state.clients.filter((client) => client.id !== action.payload),
      };

    default:
      return state;
  }
};

export default reducer;
