import "cropperjs/dist/cropper.css";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Cropper from "react-cropper";
import { FileUploader } from "react-drag-drop-files";

const defaultSrc =
  "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

const fileTypes = ["JPG", "JPEG", "PNG"];

export const ImageUploder = ({ data, handleChange, deleteImage }) => {
  const [image, setImage] = useState(defaultSrc);
  const [cropData, setCropData] = useState();
  const [cropper, setCropper] = useState();
  const [showCrap, setShowCrap] = useState(false);

  React.useEffect(() => {
    if (data !== null) {
      setCropData(process.env.REACT_APP_SERVER_URL + "/storage/" + data);
    }
  }, []);

  const handleDeleteImage = () => {
    deleteImage();
    setCropper(null);
    setCropData(null);
  };

  const onChange = (e) => {
    // e.preventDefault();

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    } else {
      files = e;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setShowCrap(!showCrap);
    };
    reader.readAsDataURL(files);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      handleChange(cropper.getCroppedCanvas().toDataURL());
      setShowCrap(!showCrap);
    }
  };

  return (
    <div>
      <div style={{ width: "100%" }}>
        <div style={!cropData ? { display: "block" } : { display: "none" }}>
          <FileUploader handleChange={onChange} name="file" types={fileTypes} />
        </div>

        <div
          className="img-preview2"
          style={!cropData ? { display: "none" } : { display: "block" }}
        >
          {cropper ? (
            <img src={cropper?.getCroppedCanvas()?.toDataURL()} alt="" />
          ) : (
            <img src={cropData} alt="" />
          )}

          <span
            className="text-danger d-block mt-2"
            onClick={handleDeleteImage}
          >
            Remove image
          </span>
        </div>

        <Modal
          className="edit-bank"
          size="lg"
          show={showCrap}
          onHide={() => setShowCrap(!showCrap)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Crop Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Cropper
              style={{ height: 400, width: "100%" }}
              zoomTo={0.5}
              initialAspectRatio={1}
              preview=".img-preview"
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides={true}
            />

            <div>
              <div className="box" style={{ display: "flex" }}>
                <button
                  className="btn btn-danger"
                  style={{
                    marginRight: "auto",
                    display: "block",
                    marginTop: 30,
                  }}
                  onClick={() => setShowCrap(!showCrap)}
                >
                  Cancel
                </button>
                <button
                  style={{
                    marginLeft: "auto",
                    display: "block",
                    marginTop: 30,
                  }}
                  className="btn btn-dark"
                  onClick={getCropData}
                >
                  Crop Image
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default ImageUploder;
