import React from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { mdiDelete } from "@mdi/js";
import Icon from "@mdi/react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next"; // Add this for translation

import * as yup from "yup";
import Api from "../../../../api/Api";
import {
  addTestimonial,
  updateTestimonial,
} from "../../../../state/action-creator/testimonials-actions";
import { ImageUploder } from "./ImageUploder";

const schema = yup.object().shape({
  name: yup.string().required(),
});

export default function AddTestimonial({ show, handleClose, data }) {
  const { t } = useTranslation(); // Use translation hook
  const [buttonText, setButtonText] = React.useState(t("Add Testimonial"));
  const [files, setFiles] = React.useState(null);
  const [image, setImage] = React.useState(null);
  const [sizes, setSizes] = React.useState([]);
  const [count, setCount] = React.useState(0);

  const [sizesInput, setSizesInput] = React.useState({
    size: "",
    sizeprice: "",
  });

  const [categories, setCategories] = React.useState([]);

  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (count > 0) {
      handleClose();
    }

    setCount((count) => count + 1);
  }, [state.items.items]);

  React.useEffect(() => {
    if (Object.keys(data).length !== 0) {
      setButtonText(() => t("Update Testimonial"));
    }

    setImage({ type: "", url: data.image });
  }, []);

  const deleteImage = () => {
    setImage({ type: true, url: data });
  };

  function handleChange(croppedFile) {
    if (croppedFile) {
      setFiles(croppedFile);
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: data.name,
      description: data.description,

      lang: data.lang,
    },
  });

  const onSubmit = (itemData) => {
    if (Object.keys(data).length !== 0) {
      dispatch(
        updateTestimonial({
          id: data?.id,
          image: files,
          imageDelete: image,
          name: itemData.name,
          description: itemData.description,

          lang: itemData.lang,
        })
      );
    } else {
      dispatch(
        addTestimonial({
          id: data?.id,
          image: files,
          imageDelete: image,
          name: itemData.name,
          description: itemData.description,

          lang: itemData.lang,
        })
      );
    }

    handleClose();
  };

  return (
    <Modal
      className="add-edit-item"
      size="lg"
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{buttonText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-5 imageCropper">
            <ImageUploder
              data={data.image ? data.image : null}
              handleChange={handleChange}
              deleteImage={deleteImage}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <label htmlFor="name">{t("Name")}</label>
            <Form.Control
              type="text"
              id="name"
              className={errors.name ? "is-invalid" : null}
              placeholder={t("Name")}
              {...register("name")}
            />
            <small className="invalid-feedback">{errors.name?.message}</small>
          </Form.Group>

          <Form.Group className="mb-4">
            <label htmlFor="description">{t("Description")}</label>
            <textarea
              id="description "
              className={
                errors.description ? "form-control is-invalid" : "form-control"
              }
              rows="10"
              placeholder={t("Description")}
              {...register("description")}
            ></textarea>
            <small className="invalid-feedback">
              {errors.description?.message}
            </small>
          </Form.Group>

          <Form.Group className="mb-4">
            <label htmlFor="language">{t("Language")}</label>
            <Form.Select
              className={errors.lang ? "is-invalid" : ""}
              {...register("lang")}
            >
              <option value="">{t("Select_Language")}</option>
              <option value="en">{t("English")}</option>
              <option value="ar">{t("Arabic")}</option>
            </Form.Select>
            <small className="invalid-feedback">{errors.lang?.message}</small>
          </Form.Group>

          {!state.items.loading ? (
            <button type="submit" className="btn btn-dark mr-2">
              {buttonText}
            </button>
          ) : (
            <div className="spinner-border" role="status">
              <span className="sr-only">{t("Loading")}</span>
            </div>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
}
