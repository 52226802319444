import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useTranslation } from "react-i18next"; // Import for translations

import { Modal } from "react-bootstrap";

import { useDispatch } from "react-redux";
import {
  addCategory,
  updateCategory,
} from "../../../../state/action-creator/items-actions";
import ImageUploder from "./ImageUploder";

const schema = yup.object().shape({
  name: yup.string().required(),
  lang: yup.string().required(),
});

export default function CategoryAdd({ show, handleClose, setLoading, data }) {
  const { t } = useTranslation(); // Use translation hook
  const dispatch = useDispatch();
  const [image, setImage] = React.useState(null);
  const [files, setFiles] = React.useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: data,
  });

  const onSubmit = (FromData) => {
    setLoading(() => true);

    Object.keys(data).length !== 0
      ? dispatch(
          updateCategory({
            id: data?.id,
            name: FromData.name,
            lang: FromData.lang,
            index: data.index ? data.index : null,
            image: files,
            imageDelete: image,
          })
        )
      : dispatch(
          addCategory({
            name: FromData.name,
            lang: FromData.lang,
            index: null,
            image: files,
            imageDelete: image,
          })
        );

    handleClose();
    setLoading(() => false);
  };

  const deleteImage = () => {
    setImage({ type: true, url: data });
  };

  function handleChange(croppedFile) {
    if (croppedFile) {
      setFiles(croppedFile);
    }
  }

  return (
    <Modal
      className="edit-bank"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {Object.keys(data).length !== 0
            ? t("Update Category")
            : t("Add Category")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-5 imageCropper">
            <ImageUploder
              data={data.image ? data.image : null}
              handleChange={handleChange}
              deleteImage={deleteImage}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <label htmlFor="category">{t("Category Name")}</label>
            <Form.Control
              type="text"
              className={errors.name ? "is-invalid" : null}
              id="category"
              placeholder={t("Category Name")}
              {...register("name")}
            />
            <small className="invalid-feedback">{errors.name?.message}</small>
          </Form.Group>

          <Form.Group className="mb-4">
            <label htmlFor="language">{t("Language")}</label>
            <Form.Select
              className={errors.lang ? "is-invalid" : ""}
              {...register("lang")}
            >
              <option value="">{t("Select Language")}</option>
              <option value="en">{t("English")}</option>
              <option value="ar">{t("Arabic")}</option>
            </Form.Select>
            <small className="invalid-feedback">{errors.lang?.message}</small>
          </Form.Group>

          <input type="hidden" {...register("index")} />

          <button className="btn btn-dark mr-2">
            {Object.keys(data).length !== 0
              ? t("Update Category")
              : t("Add Category")}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}
