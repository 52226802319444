import React from "react";
import NavbarSection from "./elements/Navbar";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./elements/Footer";
import { addToCart } from "../../state/action-creator/cart_actions";
import { useTranslation } from "react-i18next";
import "../../public.css";

export default function Product() {
  const location = useLocation();
  const productData = location.state?.productData;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth.public);
  const country = useSelector((state) => state.auth.country);
  const { t, i18n } = useTranslation();

  const handleAddToCart = (product) => {
    const itemData = {
      id: product.id,
      item: product,
      howMany: 1,
      finalPrice: product.price,
    };

    dispatch(addToCart(itemData));
  };

  const relatedProducts = data?.items
    ?.filter(
      (product) =>
        product.lang === i18n.language && // Filter by language
        product.category_id === productData.category_id && // Filter by same category
        product.id !== productData.id // Exclude the current product
    )
    .slice(0, 3);

  return (
    <>
      <NavbarSection data={data} />

      <div className="pagebanner">
        <div className="overlay"></div>
        <h1>{productData.name}</h1>
      </div>

      <div className="single-product py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                src={
                  "https://api.baderaldeensweets.com/public/storage/" +
                  productData.image
                }
                className="card-img-top"
                alt={productData.name}
              />
            </div>
            <div className="col-md-6">
              <h2>{productData.name}</h2>
              <p className="card-text text-primary fw-bold">
                {productData.price} {t("OMR")}
              </p>
              <p>{productData.description}</p>
              {country !== null && country !== "SY" ? (
                <button
                  onClick={() => handleAddToCart(productData)}
                  className="btn btn-primary rounded-pill"
                >
                  <i className="mdi mdi-cart me-2"></i>
                  {t("Order now")}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="related py-5">
        <h2 className="text-center mb-5 text-primary">
          {t("Similar Products")}
        </h2>

        <div className="container">
          <div className="row">
            {relatedProducts
              ? relatedProducts
                  .filter((product) => product.lang === i18n.language) // Filter products by language
                  .map((product) => (
                    <Link
                      to={{
                        pathname: "/product/" + product.id,
                        state: { productData: product, data: data },
                      }}
                      key={product.id}
                      className="col-md-4 mb-4"
                    >
                      <div className="card h-100 border-0 shadow-sm">
                        <img
                          src={
                            "https://api.baderaldeensweets.com/public/storage/" +
                            product.image
                          }
                          className="card-img-top"
                          alt={product.name}
                        />
                        <div className="card-body">
                          <h5 className="card-title">{product.name}</h5>
                          <p className="card-text text-primary fw-bold">
                            {product.price} {t("OMR")}
                          </p>
                          {country !== null && country !== "SY" ? (
                            <button
                              onClick={() => handleAddToCart(product)}
                              className="btn btn-primary w-100 rounded-pill"
                            >
                              <i className="mdi mdi-cart me-2"></i>
                              {t("Order now")}
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </Link>
                  ))
              : null}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
