import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import notificationsReducer from "./notificationsReducer";
import cartReducer from "./cartReducer";
import ordersReducer from "./ordersReducer";
import itemsReducer from "./itemsReducer";
import pagesReducer from "./pagesReducer";
import blogReducer from "./blogReducer";
import clientsReducer from "./clientsReducer";
import testimonialsReducer from "./testimonialsReducer";
import certificatesReducer from "./certificatesReducer";
import logocertificatesReducer from "./logocertificatesReducer";

const reducers = combineReducers({
  auth: authReducer,
  error: errorReducer,
  notifications: notificationsReducer,
  cart: cartReducer,
  orders: ordersReducer,
  items: itemsReducer,
  pages: pagesReducer,
  blog: blogReducer,
  clients: clientsReducer,
  testimonials: testimonialsReducer,
  certificates: certificatesReducer,
  logocertificates: logocertificatesReducer,
});

export default reducers;
