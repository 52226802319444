import { ActionTypes } from "../action-creator/types";

const reducer = (
  state = { logocertificates: [], loading: false, errors: false },
  action
) => {
  switch (action.type) {
    case ActionTypes.LOAD_CERTIFICATES_LOGO:
      return {
        ...state,
        logocertificates: action.payload.logocertificates,
      };

    case ActionTypes.ADD_CERTIFICATE_LOGO:
      return {
        ...state,
        logocertificates: [...state.logocertificates, action.payload],
      };

    case ActionTypes.UPDATE_CERTIFICATE_LOGO:
      return {
        ...state,
        logocertificates: state.logocertificates.map((client) =>
          client.id === action.payload.id ? action.payload : client
        ),
      };

    case ActionTypes.DELETE_CERTIFICATE_LOGO:
      return {
        ...state,
        logocertificates: state.logocertificates.filter(
          (client) => client.id !== action.payload
        ),
      };

    default:
      return state;
  }
};

export default reducer;
