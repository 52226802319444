import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import NavbarSection from "./elements/Navbar";
import { useSelector } from "react-redux";
import Footer from "./elements/Footer";
import { useTranslation } from "react-i18next";
import axios from "axios"; // Import Axios
import Api from "../../api/Api";

export default function Rates() {
  const data = useSelector((state) => state.auth.public);
  const { t } = useTranslation();
  const form = useRef(); // Create a ref for the form
  const [rating, setRating] = useState(0); // State for the star rating
  const [hoverRating, setHoverRating] = useState(0); // State for the hovered star
  const [loading, setLoading] = useState(false); // State for button loading
  const [message, setMessage] = useState(""); // State for success/error message
  const [messageType, setMessageType] = useState(""); // State for message type (success or error)

  const sendEmail = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true); // Set loading state to true
    setMessage(""); // Clear any previous messages

    // Collect form data
    const formData = new FormData(form.current);
    formData.append("date", new Date().toLocaleDateString()); // Add current date

    try {
      const response = await Api.post("/send-rates", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        setMessage("Message sent successfully!");
        setMessageType("success");
        form.current.reset(); // Clear the form
        setRating(0); // Reset the rating
      } else {
        setMessage("An error occurred, please try again.");
        setMessageType("error");
      }
    } catch (error) {
      console.error("Error:", error.response || error.message);
      setMessage("An error occurred, please try again.");
      setMessageType("error");
    } finally {
      setLoading(false); // Set loading state to false
    }
  };

  const handleRating = (rate) => {
    setRating(rate); // Set the selected rating
  };

  const handleHover = (rate) => {
    setHoverRating(rate); // Set the hovered rating
  };

  const handleMouseLeave = () => {
    setHoverRating(0); // Reset hover rating when the mouse leaves
  };

  return (
    <>
      <NavbarSection data={data} />
      <motion.div
        className="pagebanner"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="overlay"></div>
        <h1>{t("Happy to serve you")}</h1>
        <p>
          {t(
            "Share your opinion with us so we can always provide you with the best"
          )}
        </p>
      </motion.div>

      <motion.div
        id="menu"
        className="menu-products rates-page py-5"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="bg2"></div>
        <div className="container py-5">
          <div className="row">
            <motion.div
              className="col-md-12"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <form className="p-3" ref={form} onSubmit={sendEmail}>
                <div className="row mb-4">
                  <div className="col-md-6">
                    <label
                      className="form-label"
                      style={{ color: "#417E71", fontWeight: 600 }}
                    >
                      <i className="mdi mdi-account"></i>
                      {t("Full Name")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="full_name"
                      placeholder={t("Full Name")}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label
                      className="form-label"
                      style={{ color: "#417E71", fontWeight: 600 }}
                    >
                      <i className="mdi mdi-phone "></i>
                      {t("Phone Number")}
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      name="phone"
                      placeholder={t("Phone Number")}
                      required
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <label
                    className="form-label"
                    style={{ color: "#417E71", fontWeight: 600 }}
                  >
                    <i className="mdi mdi-message "></i>
                    {t("Your opinion about us")}
                  </label>
                  <textarea
                    className="form-control"
                    name="message"
                    rows="4"
                    placeholder={t("Your opinion about us")}
                    required
                  ></textarea>
                </div>

                <div className="mb-4 text-center">
                  <label
                    className="form-label"
                    style={{ color: "#417E71", fontWeight: 600 }}
                  >
                    <i className="mdi mdi-star "></i>
                    {t("Rate our service")}
                  </label>
                  <div className="rating">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <span
                        key={star}
                        onClick={() => handleRating(star)}
                        onMouseEnter={() => handleHover(star)}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          cursor: "pointer",
                          color:
                            star <= (hoverRating || rating)
                              ? "#417E71"
                              : "#e4e5e9",
                          fontSize: "24px",
                        }}
                      >
                        ★
                      </span>
                    ))}
                  </div>
                  <input type="hidden" name="rating" value={rating} />
                </div>

                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-send"
                    style={{
                      width: "100%",
                      backgroundColor: "#417E71",
                      cursor: loading ? "not-allowed" : "pointer",
                    }}
                    disabled={loading}
                  >
                    {loading ? t("Sending...") : t("Send")}
                  </button>
                  {message && (
                    <p
                      className={`mt-2 text-center ${
                        messageType === "success"
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {message}
                    </p>
                  )}
                </div>
              </form>
            </motion.div>
          </div>
        </div>
      </motion.div>

      <Footer />
    </>
  );
}
