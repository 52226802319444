import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../../../state/action-creator/cart_actions";
import AddToCart from "./AddToCart";
import Checkout from "./Checkout";
import { useTranslation } from "react-i18next";

export default function CartModel({ handleClose, show, data, groups }) {
  const [showAdd, setShowAdd] = React.useState(false);

  const [showCheckout, setShowCheckout] = React.useState(false);
  const [showItem, setItem] = React.useState({});

  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleAdd = (item, e) => {
    if (e.target.tagName !== "svg") {
      setShowAdd(!showAdd);
      setItem(item);
    } else {
      dispatch(removeFromCart(item));
    }
  };

  const handleShowCheckout = () => {
    setShowCheckout(!showCheckout);
  };

  return (
    <Modal
      className="cart-modal"
      size="lg"
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("The Cart")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {state.cart.cart.length ? (
          state.cart.cart.map((item) => (
            <div key={item.id}>
              <Dropdown.Item
                className="dropdown-item preview-item"
                style={{
                  background: "#fff",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <div className="preview-item-content w-100 d-flex align-items-start flex-column justify-content-center">
                  <div className="media">
                    <div className="position-relative mr-3">
                      <img
                        src={`${process.env.REACT_APP_SERVER_URL}/storage/${item.item.image}`}
                        alt=""
                        width="64"
                        height="64"
                      />
                      <div className="count">{item.howMany}</div>
                    </div>
                    <div className="media-body">
                      <h5 className="font-weight-bold">{item.item.name}</h5>
                    </div>
                    <div className="price font-weight-bold mr-2">
                      {`${item.finalPrice}`} {t("OMR")}
                    </div>

                    <Icon
                      path={mdiClose}
                      size={"25px"}
                      color="#ff6464"
                      onClick={() => dispatch(removeFromCart(item))}
                    />
                  </div>
                </div>
              </Dropdown.Item>
              <div className="dropdown-divider"></div>
            </div>
          ))
        ) : (
          <p> {t("No items have been added")}</p>
        )}
        {state.cart.cart.length ? (
          <button
            className="btn checkout-btn btn-lg btn-block"
            onClick={handleShowCheckout}
          >
            {t("Checkout")}
          </button>
        ) : null}
      </Modal.Body>

      {showAdd ? (
        <AddToCart
          show={showAdd}
          handleClose={setShowAdd}
          data={showItem.item}
          groups={showItem.item.groups}
          username={showItem.username}
          prevselectedGroups={showItem.selectedGroups}
          prevselectedSize={showItem.selectedSize}
          prevhowMany={showItem.howMany}
          prevNote={showItem.note}
          update={true}
        />
      ) : null}

      {showCheckout ? (
        <Checkout
          show={showCheckout}
          data={data}
          handleClose={setShowCheckout}
        />
      ) : null}
    </Modal>
  );
}
