import React, { useEffect } from "react";
import { Container, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../../../state/action-creator/cart_actions";
import { ActionTypes } from "../../../state/action-creator/types";
import { loadPublic } from "../../../state/action-creator/auth-actions";
import { Link, useLocation } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiCartOutline, mdiClose } from "@mdi/js";
import Checkout from "./Checkout";
import { useTranslation } from "react-i18next";

export default function NavbarSection() {
  const [showCheckout, setShowCheckout] = React.useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth.public);
  const state = useSelector((state) => state);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch({ type: ActionTypes.LOAD_CART });
    dispatch(loadPublic());

    // Add event listener to detect screen size changes
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  const handleAdd = (item, e) => {
    if (e.target.tagName === "svg") {
      dispatch(removeFromCart(item));
    }
  };

  const handleShowCheckout = () => {
    setShowCheckout(!showCheckout);
  };

  const toggleNavbar = () => {
    setIsNavbarOpen((prevState) => !prevState); // Toggle state
  };

  const isActive = (path) => location.pathname === path;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header>
      <nav className="navbar navbar-expand-lg sticky-top">
        <Container>
          <Link className="navbar-brand" to="/">
            <img
              src={
                i18n.language === "en"
                  ? `https://api.baderaldeensweets.com/public/storage/${data?.data.logo}`
                  : `https://api.baderaldeensweets.com/public/storage/${data?.data.logo_ar}`
              }
              alt="Badr Al-Din Logo"
              height="64"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar} // Use the custom toggle function
            aria-controls="navbarNav"
            aria-expanded={isNavbarOpen} // Set aria-expanded based on state
            aria-label="Toggle navigation"
          >
            <span className="mdi mdi-menu"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`}
            id="navbarNav"
          >
            <ul className="navbar-nav m-auto">
              <li className="nav-item">
                <Link
                  to="/"
                  className={`nav-link ${isActive("/") ? "active" : ""}`}
                >
                  {t("Home")}
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  to="/syria"
                  className={`nav-link ${isActive("/syria") ? "active" : ""}`}
                >
                  {t("Syria menu")}
                </Link>
              </li> */}
              {/* {state.auth.country !== null && state.auth.country !== "SY" && (
                <li className="nav-item">
                  <Link
                    to="/amman"
                    className={`nav-link ${isActive("/amman") ? "active" : ""}`}
                  >
                    {t("Amman menu")}
                  </Link>
                </li>
              )} */}

              {!isMobile ? (
                <Dropdown as="li" className="nav-item">
                  <Dropdown.Toggle
                    as="a"
                    className={`nav-link dropdown-toggle ${
                      isActive("/syria") || isActive("/amman") ? "active" : ""
                    }`}
                    href="#"
                    role="button"
                  >
                    {t("Menu")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/syria">
                      {t("Syria menu")}
                    </Dropdown.Item>
                    {state.auth.country !== null &&
                      state.auth.country !== "SY" && (
                        <Dropdown.Item as={Link} to="/amman">
                          {t("Amman menu")}
                        </Dropdown.Item>
                      )}
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <>
                  <li className="nav-item">
                    <Link
                      to="/syria"
                      className={`nav-link ${
                        isActive("/syria") ? "active" : ""
                      }`}
                    >
                      {t("Syria menu")}
                    </Link>
                  </li>
                  {state.auth.country !== null && state.auth.country !== "SY" && (
                    <li className="nav-item">
                      <Link
                        to="/amman"
                        className={`nav-link ${
                          isActive("/amman") ? "active" : ""
                        }`}
                      >
                        {t("Amman menu")}
                      </Link>
                    </li>
                  )}
                </>
              )}
              <li className="nav-item">
                <Link
                  to="/news"
                  className={`nav-link ${isActive("/news") ? "active" : ""}`}
                >
                  {t("News & Events")}
                </Link>
              </li>
              {data?.pages
                ?.filter((page) => page.language === i18n.language)
                .map((page) => (
                  <li className="nav-item" key={page.id}>
                    <Link
                      to={`/page/${page.slug}`}
                      className={`nav-link ${
                        isActive(`/page/${page.slug}`) ? "active" : ""
                      }`}
                    >
                      {page.title}
                    </Link>
                  </li>
                ))}
              <li className="nav-item">
                <Link
                  to="/careers"
                  className={`nav-link ${isActive("/careers") ? "active" : ""}`}
                >
                  {t("Careers")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/clients"
                  className={`nav-link ${isActive("/clients") ? "active" : ""}`}
                >
                  {t("Our Clients")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/rates"
                  className={`nav-link ${isActive("/rates") ? "active" : ""}`}
                >
                  {t("Rates")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/contact"
                  className={`nav-link ${isActive("/contact") ? "active" : ""}`}
                >
                  {t("Contact us")}
                </Link>
              </li>
            </ul>

            <div className="d-flex align-items-center">
              {state.auth.isAuthenticated ? (
                <Link
                  to="/dashboard"
                  className="text-decoration-none text-primary"
                >
                  <Icon path={mdiCartOutline} size={"20px"} color="#7c6139" />
                  {t("Dashboard")}
                </Link>
              ) : (
                <Link to="/login" className="text-decoration-none text-primary">
                  <Icon path={mdiCartOutline} size={"20px"} color="#7c6139" />
                  {t("Login")}
                </Link>
              )}
              <Dropdown>
                <Dropdown.Toggle className="nav-link nav-button cart-number">
                  <Icon path={mdiCartOutline} size={"20px"} color="#7c6139" />
                  <span className="cart-number-item">
                    {state.cart.cart.length}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list preview-cart">
                  <h6 className="cart-title">{t("All Items")}</h6>
                  {state.cart.cart.length ? (
                    state.cart.cart.map((item) => (
                      <div key={item.id}>
                        <Dropdown.Item
                          className="dropdown-item preview-item"
                          onClick={(e) => handleAdd(item, e)}
                        >
                          <div className="preview-item-content w-100 d-flex align-items-start">
                            <div className="media">
                              <img
                                src={`${process.env.REACT_APP_SERVER_URL}/storage/${item.item.image}`}
                                alt=""
                                width="64"
                                height="64"
                              />
                              <div className="media-body">
                                <h5 className="font-weight-bold">
                                  {item.item.name}
                                </h5>
                                <div className="price font-weight-bold">
                                  {`${item.finalPrice}`} {t("OMR")}
                                </div>
                              </div>
                              <Icon
                                path={mdiClose}
                                size={"25px"}
                                color="#ff6464"
                              />
                            </div>
                          </div>
                        </Dropdown.Item>
                      </div>
                    ))
                  ) : (
                    <p>{t("No items have been added")}</p>
                  )}
                  <div className="dropdown-divider"></div>
                  {state.cart.cart.length ? (
                    <button
                      className="btn checkout-btn btn-lg btn-block"
                      onClick={handleShowCheckout}
                    >
                      {t("Checkout")}
                    </button>
                  ) : null}
                </Dropdown.Menu>
              </Dropdown>

              <div className="language-switcher">
                <a
                  onClick={() =>
                    changeLanguage(i18n.language === "ar" ? "en" : "ar")
                  }
                >
                  {i18n.language === "ar" ? "EN" : "AR"}
                </a>
              </div>
            </div>
          </div>
        </Container>
      </nav>
      {showCheckout && (
        <Checkout
          show={showCheckout}
          data={data}
          handleClose={() => setShowCheckout(false)}
        />
      )}
    </header>
  );
}
