import { ActionTypes } from "./types";

import { toast } from "react-toastify";
import Api from "../../api/Api";

export const loadOrders = () => (dispatch) => {
  Api.get("/orders/all").then((response) => {
    dispatch({
      type: ActionTypes.LOAD_ORDERS,
      payload: response.data,
    });
  });
};

export const addOrders = (order) => (dispatch) =>
  dispatch({
    type: ActionTypes.ADD_ORDERS,
    payload: order,
  });

export const changeState = (data) => (dispatch) => {
  let req = Api.post("/orders/changestate", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({ type: ActionTypes.UPDATE_ORDER, payload: data.data.data });
        console.log(data.data.data);

        return "The order is marked as " + data.data.data.status;
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Faild To Update Order Status";
      },
    },
  });
};

export const ordersChangeState = (data) => (dispatch) => {
  let req = Api.post("/orders/orderschangestate", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render() {
        dispatch({ type: ActionTypes.UPDATE_ALL_ORDERS, payload: data.ids });
        return "All orders is marked as " + data.status;
      },
    },
    error: {
      render({ data }) {
        return "Faild To Update Orders Status";
      },
    },
  });
};

export const acceptDeliver = (data) => (dispatch) => {
  const req = Api.post("/orders/acceptdeliver", { order_id: data });

  toast.promise(req, {
    pending: "Order Updating",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.ACCEPT_DELIVERY,
          payload: data.data.data,
        });
        return "The Delivery has been accepted";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message.field_errors[0].error;
      },
    },
  });
};

export const handelRefundOrder = (data) => (dispatch) => {
  let req = Api.post("/orders/refund", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        //dispatch({ type: ActionTypes.UPDATE_ALL_ORDERS, payload: data.ids });
        dispatch({ type: ActionTypes.UPDATE_ORDER, payload: data.data.data });
        return "Order Refunded";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};

export const handleCancelOrder = (data) => (dispatch) => {
  let req = Api.post("/orders/cancel", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({ type: ActionTypes.UPDATE_ORDER, payload: data.data.data });
        return "Order Cancelled";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};
